export const ApplicationRoles = {
  accounting: 'Accounting',
  activityAssign: 'ActivityAssign',
  crm: 'Crm',
  folio: 'Folio',
  groups: 'Groups',
  inboxCoordinator: 'InboxCoordinator',
  mirAdmin: 'MirAdmin',
  officeAdmin: 'OfficeAdmin',
  obtSuperUser: 'ObtSuperUser',
  obtAuthBooker: 'ObtAuthBooker',
  obtUser: 'ObtUser',
  reservations: 'Reservations',
  staff: 'Staff',
  systemAdmin: 'SystemAdmin',
  userAdmin: 'UserAdmin',
  developer: 'Developer',
  obtBeta: 'ObtBeta'
};

export const AllAccessRoles = [ApplicationRoles.systemAdmin, ApplicationRoles.developer];
