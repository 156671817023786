import { useQuery } from '@tanstack/react-query';
import { getMainDbAxios } from 'src/lib/axios';

const checkPersonalCardAvailableQuery = async (isPersonalMode: boolean): Promise<boolean> => {
  return (await getMainDbAxios().get(`/api/onlinebookingprofile/checkPersonalCardAvailableQuery/${isPersonalMode}`, {}))
    .data;
};

export const useCheckPersonalCardAvailableQuery = (isPersonalMode: boolean) => {
  return useQuery({
    queryKey: ['checkPersonalCardAvailableQuery', isPersonalMode],
    queryFn: () => checkPersonalCardAvailableQuery(isPersonalMode)
  });
};
